export interface BuilderTheme {
    colors: {
        primary: string;
        secondary: string;
        background: string;
        secondaryBackground: string;
        text: string;
        border: string;
        hover: string;
        disabled: string;
        shadow: string;
        loading: string;
    };
    typography: {
        h1: {
            fontFamily: string;
            fontSize: number;
            fontWeight: 'normal' | 'bold' | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";
            lineHeight: number;
            letterSpacing: number;
        }
        p: {
            fontFamily: string;
            fontSize: number;
            fontWeight: 'normal' | 'bold' | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900";
            lineHeight: number;
            letterSpacing: number;
        }
    };
    spacing: {
        padding: number;
        margin: number;
        borderRadius: number;
        borderWidth: number;
    };
    icons: {
        size: number;
        color: string;
    };
}

const lightTheme: BuilderTheme = {
    colors: {
        primary: '#1976d2',
        secondary: '#424242',
        background: '#ffffff',
        secondaryBackground: '#F0E5FF',
        text: '#000000',
        border: '#e0e0e0',
        hover: '#1565c0',
        disabled: '#bdbdbd',
        shadow: '#171717',
        loading: '#F1F5F9'
    },
    typography: {
        h1: {
            fontFamily: 'Arial, sans-serif',
            fontSize: 14,
            fontWeight: '700',
            lineHeight: 22,
            letterSpacing: 0.5,
        },
        p: {
            fontFamily: 'Arial, sans-serif',
            fontSize: 14,
            fontWeight: 'normal',
            lineHeight: 22,
            letterSpacing: 0.5,
        },
    },
    spacing: {
        padding: 8,
        margin: 8,
        borderRadius: 8,
        borderWidth: 1,
    },
    icons: {
        size: 24,
        color: '#000000',
    },
};

const darkTheme: BuilderTheme = {
    ...lightTheme,
    colors: {
        ...lightTheme.colors,
        background: '#121212',
        text: '#ffffff',
        border: '#424242',
    },
};

// Export the themes
export const themes = {
    light: lightTheme,
    dark: darkTheme,
};

// Utility function to merge themes
export const mergeThemes = (customTheme?: BuilderTheme, defaultTheme: BuilderTheme = lightTheme): BuilderTheme => {
    return {
        ...defaultTheme,
        ...customTheme,
        colors: { ...defaultTheme.colors, ...(customTheme?.colors || {}) },
        typography: { ...defaultTheme.typography, ...(customTheme?.typography || {}) },
        spacing: { ...defaultTheme.spacing, ...(customTheme?.spacing || {}) },
        icons: { ...defaultTheme.icons, ...(customTheme?.icons || {}) },
    };
};

import React, { createContext, useState, useContext, useEffect } from 'react'
import { themes, mergeThemes } from './themes'

export type BuilderTheme = (typeof themes)['light']

export type ThemeNames = 'light' | 'dark' | 'custom'

type ThemeContextType = {
    theme?: BuilderTheme
    customTheme?: BuilderTheme
    setTheme: (theme: ThemeContextType['theme']) => void
}

const ThemeContext = createContext<ThemeContextType>({
    theme: undefined,
    setTheme: () => {},
})

export const ThemeProvider = ({
    children,
    theme: themeName,
    customTheme,
}: {
    children: any
    theme: ThemeNames
    customTheme?: BuilderTheme
}) => {
    const [selectedTheme, setSelectedTheme] =
        useState<ThemeContextType['theme']>()

    const isCustom = themeName === 'custom'

    useEffect(() => {
        setSelectedTheme(
            isCustom
                ? mergeThemes(customTheme)
                : themes[themeName as 'light' | 'dark'],
        )
    }, [themeName, customTheme])

    return (
        <ThemeContext.Provider
            value={{
                theme: selectedTheme,
                setTheme: setSelectedTheme,
                customTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = function () {
    const { theme, setTheme } = useContext(ThemeContext)

    return { theme, setTheme }
}
